<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 15"
  >
    <g>
      <path
        fill="currentColor"
        d="M1.5,6.5h7c1.3,0,2-1.6,1.1-2.6L6.1,0.4c-0.6-0.6-1.5-0.6-2.1,0L0.5,3.9C-0.5,4.9,0.2,6.5,1.5,6.5z M5,1.5 L8.5,5h-7L5,1.5z"
      />
      <path
        fill="currentColor"
        d="M9.3,8.8C9.1,8.6,8.8,8.5,8.5,8.5h-7c-0.3,0-0.6,0.1-0.9,0.3c-0.7,0.5-0.9,1.6-0.2,2.3l3.5,3.5 C4.2,14.9,4.6,15,5,15s0.8-0.1,1.1-0.4l3.5-3.5C10.2,10.4,10.1,9.3,9.3,8.8z"
      />
    </g>
  </svg>
</template>
